import React, { useState, useEffect } from 'react';
import sanityClient from "../client";
import { NavLink } from "react-router-dom";

const Portfolio = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `{
          "posts": *[_type == "post"] | order(_createdAt desc) {
            _id,
            title,
            body,
            shortDescription,
            slug,
            mainImage {
              asset->{
                _id,
                url
              },
            },
            categories[]->{
              title
            }
          },
          "categories": *[_type == "category"] {
            title
          }
        }`;

        const data = await sanityClient.fetch(query);
        setPortfolioData(data.posts);
        setCategories([{ title: 'All' }, ...data.categories]);
        
        let progress = 0;
        const interval = setInterval(() => {
          progress += 10;
          setLoadingProgress(progress);
          if (progress >= 100) {
            clearInterval(interval);
            setIsLoading(false);
          }
        }, 100);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const splitWords = (text) => {
    return text.split(/\s+/).filter(word => word.trim() !== '');
  };

  const getRandomColorClass = () => {
    const colors = ["red", "violet", "blue", "orange", "white"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };
  
  const truncateText = (text, limit) => {
    const words = text.split(/\s+/);
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  };
  
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredPosts = selectedCategory === 'All' ? portfolioData : portfolioData.filter(post => post.categories && post.categories.some(cat => cat.title === selectedCategory));

  if (isLoading) {
    return (
      <div className="loadingSection">
        <div>{loadingProgress}%</div>
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: `${loadingProgress}%` }}
          ></div>
        </div>
      </div>
    );
  }
  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  if (!portfolioData || portfolioData.length === 0) {
    return <div>No portfolio data available.</div>;
  }

  return (
    <div className={`fade-in`}>
      <div className="section full-height over-hide" style={{ height: "auto" }}>
        <div className="categories-tab">
          <div className="category-container">
            {categories.map(category => (
              <button
                key={category.title}
                className={category.title === selectedCategory ? 'category-pills active' : 'category-pills'}
                onClick={() => handleCategoryClick(category.title)}
              >
                {category.title}
              </button>
            ))}
          </div>
        </div>
        <section className="sec-container portfolio-container">
          {filteredPosts.map((post) => (
            <div key={post._id} className="card">
              <div className="top">
                <div className="dots">
                  <div className="dot dot-1"></div>
                  <div className="dot dot-2"></div>
                  <div className="dot dot-3"></div>
                </div>
                <p className="g-color">_&gt;</p>
              </div>
              <div>
                {post.mainImage && post.mainImage.asset ? (
                  <img src={post.mainImage.asset.url} width="100%" alt="Blog Cover" />
                ) : (
                  <div>No image available</div>
                )}
                <div className="bottom">
                  <div className="categories">
                    {post.categories && post.categories.map(category => (
                      <span
                        key={category.title}
                        className="category"
                        onClick={() => handleCategoryClick(category.title)}
                      >
                        {category.title}
                      </span>
                    ))}
                  </div>
                  <h3>{post.title}</h3>
                  <p className="code">
                  {post.shortDescription && post.shortDescription.map((block) => (
                    <React.Fragment key={block._key}>
                      {block.children.map((span, index) => (
                        <React.Fragment key={index}>
                          {splitWords(truncateText(span.text, 20)).map((word, wordIndex) => (
                            <span key={wordIndex} className={`${getRandomColorClass()} ${span.marks}`}>
                              {word}{' '}
                            </span>
                          ))}
                          {truncateText(span.text, 20) !== span.text && ''}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                  </p>
                  <NavLink className="nav-link" to={`/project/${post.slug.current}`}>
                    <p className="code">
                      <span className="blue">Learn More --&gt;</span>
                    </p>
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Portfolio;
