import React from "react";
import { NavLink } from "react-router-dom";

function Osource() {
  return (
    <div>
      <div className="opensource-featured-wrap">
        <div className="opensource-featured-card">
          <NavLink className="nav-link" to="/numberdeck"><img src="https://camo.githubusercontent.com/1f1c833451e317cec7c9a1519969b219d7f7f00969aaeb97a6382d91cd37032b/68747470733a2f2f692e696d6775722e636f6d2f614c584f784b572e706e67" /></NavLink>
          <div className="content">
            <NavLink className="nav-link" to="/numberdeck"><div><h3><i className="fab fa-github" style={{color: '#9198a1'}} />   NumberDeck </h3> &nbsp; &nbsp; <small className="chip">PUBLIC</small></div></NavLink>
            <p>
              NumberDeck is an open-source project that replaces the physical Stream Deck with your Num Pad on your keyboard. 
              NumberDeck was first designed to be helpful for streamers, but it can be used for any day-to-day work. In simple words, 
              it turns your keyboard into a fully functioning Stream Deck!
            </p>
            <div style={{display:'flex', justifyContent: 'flex-start'}}>
              <span className="code-circle" style={{backgroundColor: '#00B4AB'}}></span> &nbsp; Dart
            </div>
          </div>
        </div>
      </div>
      <div className="opensource-wrap">
        <div className="opn-card">
          <div className="opensource-card">
            <a href="https://github.com/ab-muhammad-hamza/php-login-system" target="_blank"><div><h4><i className="fab fa-github" style={{color: '#9198a1'}} />   PHP Basic Login</h4> &nbsp; &nbsp; <small className="chip">PUBLIC</small></div></a>
            <p>This GitHub repository showcases a straightforward PHP login system integrated with sessions. With a focus on simplicity and security, this project provides a foundational example of how to implement user authentication and session management in PHP applications.</p>
            <div>
              <span className="code-circle" style={{backgroundColor: '#4F5D95'}}></span> &nbsp; PHP
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default Osource;
