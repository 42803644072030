import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from 'jquery';

function Navigation() {
  useEffect(() => {
    // Page cursors setup
    document.getElementsByTagName("body")[0].addEventListener("mousemove", function(n) {
      t.style.left = n.clientX + "px";
      t.style.top = n.clientY + "px";
      e.style.left = n.clientX + "px";
      e.style.top = n.clientY + "px";
      i.style.left = n.clientX + "px";
      i.style.top = n.clientY + "px";
    });

    var t = document.getElementById("cursor"),
        e = document.getElementById("cursor2"),
        i = document.getElementById("cursor3");

    function n(t) {
      e.classList.add("hover");
      i.classList.add("hover");
    }

    function s(t) {
      e.classList.remove("hover");
      i.classList.remove("hover");
    }

    s();
    var r = document.querySelectorAll(".hover-target");

    for (var a = r.length - 1; a >= 0; a--) {
      o(r[a]);
    }

    function o(t) {
      t.addEventListener("mouseover", n);
      t.addEventListener("mouseout", s);
    }
    // Function to close the navigation menu when a link is clicked
    function closeNav() {
      document.body.classList.remove('nav-active');
    }

    // Add event listener to each link element to close the navigation menu when clicked
    const navLinks = document.querySelectorAll('.nav__list-item');
    navLinks.forEach(link => {
      link.addEventListener('click', closeNav);
    });
    var app = function () {
      var body = undefined;
      var menu = undefined;
      var menuItems = undefined;
      var init = function init() {
        body = document.querySelector('body');
        menu = document.querySelector('.menu-icon');
        menuItems = document.querySelectorAll('.nav__list-item');
        applyListeners();
      };
      var applyListeners = function applyListeners() {
        menu.addEventListener('click', function () {
          return toggleClass(body, 'nav-active');
        });
      };
      var toggleClass = function toggleClass(element, stringClass) {
        if (element.classList.contains(stringClass)) element.classList.remove(stringClass);else element.classList.add(stringClass);
      };
      init();
    }();
  }, []);
  return (
    <div>
      <header className="cd-header">
        <div className="header-wrapper">
          <div className="logo-wrap">
            <NavLink className="nav-link" to="/"><a className="hover-target">MUHMMAD<span>HAMZA</span></a></NavLink>
          </div>
          <div className="nav-but-wrap">
            <div className="menu-icon hover-target">
              <span className="menu-icon__line menu-icon__line-left" />
              <span className="menu-icon__line" />
              <span className="menu-icon__line menu-icon__line-right" />
            </div>					
          </div>					
        </div>				
      </header>
      <div className="nav">
        <div className="nav__content">
          <ul className="nav__list">
            <li className="nav__list-item"><NavLink className="nav-link" to="/"><a className="hover-target">home</a></NavLink></li>
            <li className="nav__list-item"><NavLink className="nav-link" to="/portfolio"><a className="hover-target">portfolio</a></NavLink></li>
            <li className="nav__list-item"><NavLink className="nav-link" to="/opensource"><a className="hover-target">Open Source</a></NavLink></li>
            <li className="nav__list-item"><NavLink className="nav-link" to="/about"><a className="hover-target">About Me</a></NavLink></li>
            <li className="nav__list-item"><NavLink className="nav-link" to="/contact"><a className="hover-target">contact</a></NavLink></li>
          </ul>
          <div className="socials">
            <a href="https://www.behance.net/ab-muhammad-hamza" target="_blank" rel="noreferrer" className="nav__list-item"><i className="fab fa-behance" /></a>
            <a href="https://instagram.com/ab.muhmmad.hamza" target="_blank" rel="noreferrer" className="nav__list-item"><i className="fab fa-instagram" /></a>
            <a href="https://twitter.com/abmuhammadhamza" target="_blank" rel="noreferrer" className="nav__list-item"><i className="fab fa-twitter" /></a>
            <a href="https://medium.com/@abmuhammadhamza" target="_blank" rel="noreferrer" className="nav__list-item"><i className="fab fa-medium" /></a>
            <a href="https://www.linkedin.com/in/ab-muhammad-hamza/" target="_blank" rel="noreferrer" className="nav__list-item"><i className="fab fa-linkedin" /></a>
            <a href="https://id.pinterest.com/abmuhammadhamza/pins/" target="_blank" rel="noreferrer" className="nav__list-item"><i className="fab fa-pinterest" /></a>
            <a href="https://dribbble.com/ab-muhammad-hamza" target="_blank" rel="noreferrer" className="nav__list-item"><i className="fab fa-dribbble" /></a>
            <a href="https://github.com/ab-muhammad-hamza/" target="_blank" rel="noreferrer" className="nav__list-item"><i className="fab fa-github" /></a>
          </div>
        </div>
      </div>		
    </div>
  );
}

export default Navigation;
