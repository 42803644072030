import React, { useState, useEffect } from 'react';
import './numberdeck.css';

const NumberDeck = () => {
  const [activeTab, setActiveTab] = useState('streamers'); 
  const [latestReleaseTag, setLatestReleaseTag] = useState('');

  useEffect(() => {
    const fetchLatestRelease = async () => {
      try {
        const response = await fetch('https://api.github.com/repos/ab-muhammad-hamza/NumberDeck/releases/latest');
        const data = await response.json();
        setLatestReleaseTag(data.tag_name);
      } catch (error) {
        console.error('Error fetching the latest release:', error);
      }
    };

    fetchLatestRelease();
  }, []);

  const features = {
    streamers: {
      title: "For Streamers",
      description: "Control your OBS with ease",
      items: [
        "Switch Scene",
        "Mute/Unmute Source",
        "Toggle Source Visibility",
        "Start/Stop Streaming",
        "Start/Pause/Resume/Stop Recording"
      ]
    },
    developers: {
      title: "For Developers",
      description: "Boost your productivity with custom shortcuts",
      items: [
        "Create custom macros",
        "Quick access to frequently used commands",
        "Streamline your development workflow"
      ]
    },
    creatives: {
      title: "For Creatives",
      description: "Manage your windows effortlessly",
      items: [
        "Maximize/minimize active windows",
        "Quick switch between applications",
        "Customize for your creative workflow"
      ]
    }
  };

  return (
    <div className="number-deck-landing">
      <br /><br /><br />
      <header>
        <img src="https://i.imgur.com/aLXOxKW.png" alt="NumberDeck Cover" className="header-image" />
        <h1>NumberDeck</h1>
        <p className="tagline">Transform Your Numpad into a Productivity Powerhouse</p>
        <div className="badges">
          <img src="https://img.shields.io/github/v/release/ab-muhammad-hamza/numberdeck.svg" alt="Release" />
          <img src="https://img.shields.io/github/license/ab-muhammad-hamza/numberdeck.svg" alt="License" />
          <img src="https://img.shields.io/github/stars/ab-muhammad-hamza/numberdeck.svg" alt="Stars" />
          <img src="https://img.shields.io/github/issues/ab-muhammad-hamza/numberdeck.svg" alt="Issues" />
        </div>
        <div className="cta-buttons">
          <a href={`https://github.com/ab-muhammad-hamza/NumberDeck/releases/download/${latestReleaseTag}/NumberDeck.exe`} className="btn btn-primary">Download</a>
          <a href="https://github.com/ab-muhammad-hamza/NumberDeck/issues" className="btn btn-secondary">Report Bug</a>
          <a href="https://github.com/ab-muhammad-hamza/NumberDeck/issues" className="btn btn-secondary">Request Feature</a>
        </div>
      </header>

      <section id="about" className="about">
        <h2>What is NumberDeck?</h2>
        <p>NumberDeck is an open-source project that replaces the physical Stream Deck with your Num Pad on your keyboard. It was first designed to be helpful for streamers, but it can be used for any day-to-day work. In simple words, it turns your keyboard into a fully functioning Stream Deck!</p>
        <a href="https://discord.gg/vAkRjnyPeT" className="discord-badge">
          <img src="https://dcbadge.limes.pink/api/server/vAkRjnyPeT" alt="Discord Server" />
        </a>
      </section>

      <section id="features" className="features">
        <h2>Features</h2>
        <div className="feature-tabs">
          <div className="tab-buttons">
            {Object.keys(features).map(key => (
              <button 
                key={key} 
                className={activeTab === key ? 'active' : ''}
                onClick={() => setActiveTab(key)}
              >
                {features[key].title}
              </button>
            ))}
          </div>
          <div className="tab-content">
            <h3>{features[activeTab].title}</h3>
            <p>{features[activeTab].description}</p>
            <ul>
              {features[activeTab].items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section id="installation" className="installation">
        <h2>Installation</h2>
        <div className="installation-steps">
          <div className="step">
            <div className="step-number">1</div>
            <p>Download the latest release</p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <p>Run the installer and press extract</p>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <p>You'll find a shortcut on your Desktop</p>
          </div>
          <div className="step">
            <div className="step-number">🥳</div>
            <p>Open and Enjoy</p>
          </div>
        </div>
      </section>

      <section id="tech-stack" className="tech-stack">
        <h2>Languages and Technologies</h2>
        <div className="tech-badges">
          <img src="https://img.shields.io/badge/dart-%230175C2.svg?style=for-the-badge&logo=dart&logoColor=white" alt="Dart" />
          <img src="https://img.shields.io/badge/Flutter-%2302569B.svg?style=for-the-badge&logo=Flutter&logoColor=white" alt="Flutter" />
          <img src="https://img.shields.io/badge/Windows-0078D6?style=for-the-badge&logo=windows&logoColor=white" alt="Windows" />
        </div>
      </section>
    </div>
  );
};

export default NumberDeck;